var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "mb-2 align-items-center"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-sm-center justify-content-md-start",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "8"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex flex-row align-items-center"
  }, [_c('label', {
    staticClass: "mr-1"
  }, [_c('strong', [_vm._v("Kategori")])]), _c('b-form-select', {
    attrs: {
      "options": _vm.paketCategories
    },
    model: {
      value: _vm.filteredBy,
      callback: function callback($$v) {
        _vm.filteredBy = $$v;
      },
      expression: "filteredBy"
    }
  })], 1)])], 1), _c('b-col', {
    staticClass: "d-flex justify-content-sm-center justify-content-md-end",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "blog-search",
    staticStyle: {
      "width": "400px"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "search-input",
      "placeholder": "Cari Try Out..."
    },
    model: {
      value: _vm.q,
      callback: function callback($$v) {
        _vm.q = $$v;
      },
      expression: "q"
    }
  }), _c('b-input-group-append', {
    staticClass: "cursor-pointer",
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "align-items-center justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body p-1"
  }, [_c('section', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "bg-danger p-1 bg-opacity-10 rounded"
  }, [_c('feather-icon', {
    staticClass: "text-white",
    attrs: {
      "icon": "BookIcon",
      "size": "20"
    }
  })], 1), _c('div', {
    staticClass: "ml-1"
  }, [_c('small', {
    staticClass: "d-block",
    attrs: {
      "id": "tryout_tersedia"
    }
  }, [_vm._v(" Try Out Tersedia "), _c('feather-icon', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "icon": "InfoIcon"
    }
  })], 1), _c('h1', [_c('strong', [_vm._v(_vm._s(_vm.rekap.tryout_tersedia))])]), _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "target": "tryout_tersedia",
      "title": "Tryout tersedia adalah tryout-tryout yang belum sama sekali kamu kerjakan"
    }
  })], 1)])])])])], 1), _vm.data.length > 0 ? _c('b-row', {
    staticClass: "match-height"
  }, _vm._l(_vm.data, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "6",
        "lg": "4"
      }
    }, [_c('div', {
      staticClass: "card"
    }, [_c('div', {
      staticClass: "card text-white"
    }, [_c('img', {
      staticClass: "image",
      staticStyle: {
        "width": "100%",
        "height": "170px",
        "object-fit": "cover"
      },
      attrs: {
        "src": _vm.apiFile + item.thumbnail,
        "alt": "Avatar"
      }
    }), _c('div', {
      staticClass: "overlay"
    }), _c('div', {
      staticClass: "card-img-overlay"
    }, [_c('h5', {
      staticClass: "card-title text-white",
      staticStyle: {
        "margin-top": "60px"
      }
    }, [_c('b', [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v(_vm._s(item.sum_subtest) + " Subtes")]), _c('div', {
      staticClass: "col"
    }, [_c('b-button', {
      staticClass: "btn-sm float-right",
      staticStyle: {
        "cursor": "default",
        "border-radius": "10px"
      },
      attrs: {
        "variant": "light"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50 text-primary",
      attrs: {
        "icon": "UserIcon"
      }
    }), _c('span', {
      staticClass: "align-middle"
    }, [_c('b', [_vm._v(_vm._s(item.sum_quota))]), _vm._v(" / ")]), _c('span', {
      staticClass: "align-middle text-dark"
    }, [_vm._v(" " + _vm._s(item.max_quota) + " ")])], 1)], 1)])])]), _c('div', {
      staticClass: "ml-2 mr-2 mb-2"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Waktu Mulai")])], 1), _c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Waktu Berakhir")])], 1)]), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mt-1"
    }, [_c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [item.info_start ? _c('small', {
      staticClass: "font-weight-bold",
      staticStyle: {
        "color": "black"
      }
    }, [_vm._v(" " + _vm._s(_vm.getTanggal(item.info_start)) + " WIB ")]) : _c('small', {
      staticClass: "text-danger font-weight-bold"
    }, [_c('i', [_vm._v("Tidak ada")])])]), _c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [item.info_end ? _c('small', {
      staticClass: "font-weight-bold",
      staticStyle: {
        "color": "black"
      }
    }, [_vm._v(" " + _vm._s(_vm.getTanggal(item.info_end)) + " WIB ")]) : _c('small', {
      staticClass: "text-danger font-weight-bold"
    }, [_c('i', [_vm._v("Tidak ada")])])])]), _c('div', {
      staticClass: "mt-2 mb-1"
    }, [item.jadwal_id != null ? _c('div', {}, [_c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Tryout Dimulai")])], 1), _c('div', [item.in_schedule_detail.text == 'belum_berjalan' ? _c('div', {}, [_c('div', {}, [_c('b', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(item.remaining_time))])])]) : item.in_schedule_detail.text == 'sudah_berakhir' ? _c('div', {}, [_c('div', {}, [_c('b', {
      staticClass: "text-dark"
    }, [_vm._v(_vm._s(item.remaining_time))])])]) : _c('div', {}, [_c('div', {}, [_c('b', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(item.remaining_time))])])])])]) : _vm._e()]), item.jadwal_id != null ? _c('div', {}, [_c('div', {}, [item.in_schedule_detail.text == 'belum_berjalan' ? _c('div', {}, [_c('b-button', {
      attrs: {
        "variant": "outline-dark",
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.ikutiTes(item.id, item.jadwal_id, item.name, item.info_start, item.info_end, item.in_schedule, item);
        }
      }
    }, [_vm._v(" Ikuti Tes ")])], 1) : item.in_schedule_detail.text == 'sudah_berakhir' ? _c('div', {}, [_c('b-button', {
      attrs: {
        "variant": "outline-dark",
        "disabled": "",
        "block": ""
      }
    }, [_vm._v(" Ikuti Tes ")])], 1) : _c('div', {}, [_c('b-button', {
      staticClass: "mt-2",
      attrs: {
        "variant": "outline-dark",
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.ikutiTes(item.id, item.jadwal_id, item.name, item.info_start, item.info_end, item.in_schedule, item);
        }
      }
    }, [_vm._v(" Ikuti Tes ")])], 1)])]) : _c('div', {}, [_c('b-button', {
      staticClass: "mt-2",
      attrs: {
        "variant": "outline-dark",
        "block": "",
        "disabled": ""
      }
    }, [_vm._v(" Ikuti Tes ")])], 1)])]), _c('b-modal', {
      attrs: {
        "header-bg-variant": "light-warning",
        "id": "modal-tambah",
        "centered": "",
        "title": "Peringatan!",
        "hide-footer": ""
      },
      model: {
        value: _vm.Modal,
        callback: function callback($$v) {
          _vm.Modal = $$v;
        },
        expression: "Modal"
      }
    }, [_c('p', [_vm._v("Kamu belum mengisi profil diri kamu! Silahkan isi dengan mengklik tombol dibawah ini")]), _c('center', [_c('b-button', {
      attrs: {
        "variant": "primary",
        "to": {
          name: 'profil'
        }
      }
    }, [_vm._v(" Profil ")])], 1)], 1)], 1);
  }), 1) : _c('Blank')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }